import { makeStyles } from "@mui/styles";
import { Box, Typography,Paper, styled, Table, TableBody, TableContainer, TableHead, TableRow,Card, useMediaQuery } from "@mui/material";
import { getBrokerDetails } from "../api/api";
import { useEffect, useState } from "react";
import Skeleton from '@mui/material/Skeleton';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import axios from "axios";
import { getData } from "../utils/localDataHandler";
import axiosInstance from "../utils/axios";

// import { MuiAutocomplete } from 'components/StyledInputFields';


const useStyles = makeStyles((theme) => ({
  root:{
    padding:"50px 30px",
    backgroundColor:"white",
    height:"100%",
    [theme.breakpoints.down("md")]: {
        padding: "20px",
      }
  },
  heading:{
    fontWeight:"700",
    fontSize:"24px"
  },
  card:{
    width:"240px",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    marginRight:"40px",
    padding:"23px 0px",
    border: "1px solid #FFF7F8 !important",
    boxShadow: "0px 3px 6px rgba(255, 90, 106, 0.25) !important",
    borderRadius:"10px !important"
  },
  cardHeading:{
    fontWeight:"700 !important",
    fontSize:"20px !important",
    color:"#3A3A3A",

  },
  cardData:{
    fontWeight:"700 !important",
    fontSize:"24px !important",
    marginTop:"25px !important",
    color:"#3A3A3A",

  },
  cardLinkText:{
    fontWeight:"600 !important",
    fontSize:"14px !important",
    color:"#FF5A6A",
    marginTop:"25px !important"
  },
  container: {
    display:"flex",
    margin:"30px 4px 0px 4px"
 },
 infoBox:{
    width:"100%",
    [theme.breakpoints.down("md")]: {
        width:"100%"
      }
},
headingBox:{
    display:"flex",
    alignItems: "center"
},  
cardSubheading:{
    fontSize: "20px !important",
    letterSpacing: "0.12px",
    color: "#033333",
    margin:"20px 0px 0px 0px",
    fontWeight:"700 !important"
},
cardSubheading2:{
    fontSize: "18px !important",
    letterSpacing: "0.12px",
    color: "#033333",
    margin:"20px 0px 0px 0px",
    fontWeight:"700 !important"
},
headingUnderline:{
    background: "#FF7676",
    border: "1px solid #FF7676",
    borderRadius: "20px",
    width: "80px",
    height: "4px",
    marginBottom:"8px"
},
contentHeaing:{
    fontSize: "16px",
    letterSpacing: "0.12px",
    color: "#033333",
    fontWeight:"700 !important",
    margin : "20px 0px !important"
},
contentText:{
    font: 'normal normal normal 16px/35px Roboto',
    letterSpacing: "0.11px",
    color: '#707070',
    margin : "20px 0px !important"
},
containerBox:{
    display:"flex",
    width:"100%",
    justifyContent:"space-between",
    [theme.breakpoints.down("md")]: {
        flexDirection:"column"
      }
},
agentTable:{
    width:"95%",
    [theme.breakpoints.down("md")]: {
        width:"100%"
     }
},
tableCellCls:{
    marginLeft:"20px",
    fontWeight:"800",
    fontSize:"15px",
    background:"#FF7676",
    whiteSpace:"no-wrap"
},
tableCell1:{
    textAlign:"center"
}
  }));

const AccountSettings =()=>{
    const classes = useStyles();
    const tableData = [{
        "name":"Good Charlie",
        "plan_term": "45m",
        "plan_id": "2060",
        "upfront": "p47",
        "residual": "6mills",
        "payment": "Monthly",
        "clawback": "19",
        "date": "07/08/2022",
        "utm_content": "GOOD",
        "status": "Pending"
      }, {
        "plan_term": "96m",
        "plan_id": "2049",
        "upfront": "g85",
        "residual": "1mills",
        "payment": "Monthly",
        "clawback": "82",
        "date": "04/17/2023",
        "utm_content": "GOOD",
        "status": "Pending"
      }, {
        "plan_term": "76m",
        "plan_id": "2027",
        "upfront": "l17",
        "residual": "2mills",
        "payment": "Monthly",
        "clawback": "90",
        "date": "07/10/2022",
        "utm_content": "GOOD",
        "status": "Pending"
      }, {
        "plan_term": "56m",
        "plan_id": "2089",
        "upfront": "h36",
        "residual": "1mills",
        "payment": "Monthly",
        "clawback": "05",
        "date": "12/09/2022",
        "utm_content": "GOOD",
        "status": "Pending"
      }, {
        "plan_term": "56m",
        "plan_id": "2056",
        "upfront": "p39",
        "residual": "4mills",
        "payment": "Monthly",
        "clawback": "50",
        "date": "03/26/2023",
        "utm_content": "GOOD",
        "status": "Pending"
      }, {
        "plan_term": "55m",
        "plan_id": "2011",
        "upfront": "986",
        "residual": "3mills",
        "payment": "Monthly",
        "clawback": "38",
        "date": "07/04/2022",
        "utm_content": "GOOD",
        "status": "Pending"
      }, {
        "plan_term": "91m",
        "plan_id": "2056",
        "upfront": "o66",
        "residual": "5mills",
        "payment": "Monthly",
        "clawback": "12",
        "date": "12/16/2022",
        "utm_content": "GOOD",
        "status": "Pending"
      }, {
        "plan_term": "63m",
        "plan_id": "2013",
        "upfront": "l73",
        "residual": "6mills",
        "payment": "Monthly",
        "clawback": "16",
        "date": "08/19/2022",
        "utm_content": "GOOD",
        "status": "Pending"
      }, {
        "plan_term": "77m",
        "plan_id": "2075",
        "upfront": "k57",
        "residual": "5mills",
        "payment": "Monthly",
        "clawback": "54",
        "date": "01/12/2023",
        "utm_content": "GOOD",
        "status": "Pending"
      }, {
        "plan_term": "18m",
        "plan_id": "2075",
        "upfront": "k57",
        "residual": "7mills",
        "payment": "Monthly",
        "clawback": "08",
        "date": "03/25/2023",
        "utm_content": "GOOD",
        "status": "Pending"
      }]
    const [agentData,setAgentData] = useState({
        name:"broker_name",
        sgAgentId: "sg_agent_id",
        sgUserId:"sg_user_id",
        tfnNumber: "890"
    });
    const [loading,setLoading] = useState(false);
    const heading2 = ["Plan Term","Plan Id","Upfront","Residual","Upfront","Residual"]
    const [formValue, setformValue] = useState({
        frequency: "",
        clawback: "",
        date: "",
        status: "",
        utmContent: "",
    });
    const mobileDevice = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [commissionsData,setCommissionData] = useState();
    const [allProductsData, setAllProductsData] = useState([]);
    const [showComData,setShowComData] = useState();
    const [dynamicWidth,setDynamicWidth] = useState(4)




    const months = ['PlanID', 'Plan Name', 'Plan Term'];
    const mappingDataMonth=["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec", "total"]


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "white",
            marginLeft:"20px",
            fontWeight:"800",
            fontSize:"15px",
            background:"#FF7676",
            whiteSpace:"nowrap",
            textAlign:"center",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 16,
            fontWeight: 500,
            marginLeft:"20px",
            fontWeight:"800",
            fontSize:"15px",
            background:"#FFF7F8",
            whiteSpace:"nowrap",
            textAlign:"center"
        },
    }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    //   '&:nth-of-type(odd)': {
    //       backgroundColor: "#FFF1F1",
    //   },
      // hide last border
      // '&:last-child td, &:last-child th': {
      //     border: 0,
      // },
  }));

    const handleChange = (e) => {
        // if (e.target.name === "broker_api_enabled") {
        //     let reason = e.target.checked === false ? "" : "n/a"
        //     setformValue({
        //         ...formValue,
        //         broker_api_enabled: e.target.checked ? 1 : 0,
        //         deactivation_reason: reason,
        //     })
        // } else {
            setformValue({
                ...formValue,
                [e.target.name]: e.target.type === 'checkbox' ? e.target.checked === true ? 1 : 0 : e.target.value
            });
        // }
    }

    const getTrueCount = (data) =>{
        const propertiesToCheck = [
            'acq_comp',
            'acq_comp_residual',
            'acq_comp_upfront',
            'renewal_comp',
            'renewal_comp_upfront',
            'renewal_comp_residual',
        ];

        const trueCount = Object.keys(data)?.filter(value => propertiesToCheck.includes(value) && data[value]==1)?.length;

        return trueCount;
        
    }

    const fetchData = async page => {
        const loggedInUser = getData("loggedInUser")
        let broker_id = loggedInUser && loggedInUser.broker_id
        try{
            const response = await axiosInstance.get(`internal/brokers/broker-commision`, { headers: {'x-api-key' : process.env.REACT_APP_API_KEY},params:{broker_id:broker_id}});
            if(response.data.status === 200){
                setCommissionData(response?.data?.brokerCommision)
                setAllProductsData(response?.data?.brokerProduct)
                let trueCount = getTrueCount(response?.data?.brokerCommision)
                trueCount && setDynamicWidth(trueCount)
                if(response?.data?.brokerCommision != null && response?.data?.brokerCommision?.commision_status == 1){
                    setShowComData(true)
                }
            }
        }catch(err){
            console.log('Data in err ==>', err)
        }
    };


    useEffect(()=>{
        fetchData();
        (async() => {
            setLoading(true)
            const res = await getBrokerDetails();
        if(res?.status == "200"){
            setAgentData(
                {
                    name: res?.brokers?.broker_name ,
                    sgAgentId: res?.brokers?.sg_agent_id ,
                    sgUserId: res?.brokers?.sg_user_id,
                    tfnNumber: res?.brokers?.tfn_number,
                    utmCampaign: res?.brokers?.converting_utm_campaign,
                    utmSource: res?.brokers?.converting_utm_source,
                    utmMedium: res?.brokers?.converting_utm_medium,
                    utmContent: res?.brokers?.converting_utm_content,
                }
            )
            setLoading(false)
        }else{
            setAgentData(
                {
                    name: "ElectricityBroker" ,
                    sgAgentId: "701740",
                    sgUserId: "3911",
                    tfnNumber: "1111"
                }
            )
            setLoading(false)
    }})();
    },[])
      
return(
    <Box className={classes.root}>
        <Typography fontSize="24px" fontWeight={700}>Account Settings</Typography>
        <Box className={classes.container}>
            <Box className={classes.infoBox}>
                <Box style={{width:mobileDevice?"60%":"50%"}}>
                    <Box className={classes.headingBox}>
                        <Box>
                        <Typography  className={classes.cardSubheading} align='left'>Account information</Typography>
                            <Box className={classes.headingUnderline}></Box>
                        </Box>
                    </Box>
                    <Box className={classes.containerBox}>
                        <Box sx={{display:"flex"}}>
                            <Box width="150px">
                                <Typography className={classes.contentHeaing}>Name</Typography>
                                <Typography className={classes.contentHeaing}>SG Agent ID</Typography>
                                <Typography className={classes.contentHeaing}>SG User ID</Typography>
                                <Typography className={classes.contentHeaing}>TFN Number</Typography>
                            </Box>
                            <Box>
                                {loading?
                                <>
                                    <Skeleton className={classes.contentHeaing} variant="rectangular" width={100} height={24} />
                                    <Skeleton className={classes.contentHeaing} variant="rectangular" width={100} height={24} />
                                    <Skeleton className={classes.contentHeaing} variant="rectangular" width={100} height={24} />
                                    <Skeleton className={classes.contentHeaing} variant="rectangular" width={100} height={24} />
                                </>

                                :<><Typography className={classes.contentText}>{agentData.name || "-"}</Typography>
                                <Typography className={classes.contentText}>{agentData.sgAgentId || "-"}</Typography>
                                <Typography className={classes.contentText}>{agentData.sgUserId || "-"}</Typography>
                                <Typography className={classes.contentText}>{agentData.tfnNumber || "-"}</Typography></>}
                            </Box>
                        </Box>
                        <Box sx={{display:"flex"}}>
                            <Box width="150px">
                                <Typography className={classes.contentHeaing}>UTM Campaign</Typography>
                                <Typography className={classes.contentHeaing}>UTM Source</Typography>
                                <Typography className={classes.contentHeaing}>UTM Medium</Typography>
                                <Typography className={classes.contentHeaing}>UTM Content</Typography>
                            </Box>
                            <Box>
                            {loading?
                                <>
                                    <Skeleton className={classes.contentHeaing} variant="rectangular" width={100} height={24} />
                                    <Skeleton className={classes.contentHeaing} variant="rectangular" width={100} height={24} />
                                    <Skeleton className={classes.contentHeaing} variant="rectangular" width={100} height={24} />
                                    <Skeleton className={classes.contentHeaing} variant="rectangular" width={100} height={24} />
                                </>
                                :<>
                                <Typography className={classes.contentText}>{agentData.utmCampaign || "-"}</Typography>
                                <Typography className={classes.contentText}>{agentData.utmSource || "-"}</Typography>
                                <Typography className={classes.contentText}>{agentData.utmMedium || "-"}</Typography>
                                <Typography className={classes.contentText}>{agentData.utmContent || "-"}</Typography></>}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box style={{width:"95%",borderTop:"2px solid rgba(0, 0, 0, 0.25)", margin:"10px 0px 0px 0px"}}/>
            </Box>       
        </Box>
        <Box className={classes.container}>
            <Box style={{width:"100%"}}>
                <Box className={classes.headingBox}>
                    <Box>
                        <Typography  className={classes.cardSubheading} align='left'>Commisions</Typography>
                        <Box className={classes.headingUnderline}></Box>
                    </Box>
                </Box>
              

                {showComData?<>
                    <div style={{display:"flex",width:"100%"}}>
                        <Box style={{display: "flex", justifyContent: "left", width: "50%", paddingLeft: "10px"}}>
                            <Box style={{width: "50%"}}>
                                <Typography className={classes.cardSubheading} align='left'>Payment Details</Typography>
                                <Box className={classes.headingUnderline}></Box>
                                <Box>
                                    <Box style={{display: "flex", justifyContent: "space-between", marginTop: "10px"}}>
                                        <Typography className={classes.contentHeaing}>Payment Frequency</Typography>
                                        <Typography className={classes.contentText} >{commissionsData?.payment_frequency}</Typography>
                                    </Box>
                                    <Box style={{display: "flex", justifyContent: "space-between", marginTop: "5px"}}>
                                        <Typography className={classes.contentHeaing}>Clawback Period (days)</Typography>
                                        <Typography className={classes.contentText} >{commissionsData?.clawback_period}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{display: "flex", justifyContent: "left", width: "50%"}}>
                            <Box style={{width: "40%"}}>
                                <Typography className={classes.cardSubheading} align='left'>Contractual Details</Typography>
                                <Box className={classes.headingUnderline}></Box>
                                <Box style={{display: "flex", width: "100%", justifyContent: "space-between", marginTop: "10px"}}>
                                    <Typography className={classes.contentHeaing}>Effective Date</Typography>
                                    <Typography className={classes.contentText} >{commissionsData?.effective_date}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </div>
                    <div style={{display:"flex", width:"100%", marginTop: "20px"}}>
                        <Box style={{display: "flex", justifyContent: "left", width: "50%", paddingLeft: "10px"}}>
                            <Box style={{width: "40%"}}>
                                <Typography className={classes.cardSubheading} align='left'>Status</Typography>
                                <Box className={classes.headingUnderline}></Box>
                                <Box style={{display: "flex", width: "100%", justifyContent: "space-between", marginTop: "10px"}}>
                                    <Typography className={classes.contentHeaing}>Status</Typography>
                                    <Typography className={classes.contentText} >{commissionsData?.commision_status == 0 ? "Inactive" :commissionsData?.commision_status == 1 ?"Active": "Pending"}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{display: "flex", justifyContent: "left", width: "50%"}}>
                            <Box style={{width: "40%"}}>
                                <Typography className={classes.cardSubheading} align='left'>Tracking</Typography>
                                <Box className={classes.headingUnderline}></Box>
                                <Box style={{display: "flex", width: "100%", justifyContent: "space-between", marginTop: "10px"}}>
                                    <Typography className={classes.contentHeaing}>UTM Content</Typography>
                                    <Typography className={classes.contentText} >{commissionsData?.converting_utm_content}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </div>
                    <Box className={classes.agentTable} >
                        <TableContainer className="tableContainer">
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell  colSpan={2}>
                                            Plan & Product</StyledTableCell>
                                        {commissionsData?.acq_comp && (commissionsData?.acq_comp_residual || commissionsData?.acq_comp_upfront) ? 
                                        <StyledTableCell  
                                        colSpan={(commissionsData.acq_comp && commissionsData.acq_comp_upfront && commissionsData.acq_comp_residual) ? 2 : 1}>Acquisition Compensation</StyledTableCell>:null}
                                        {commissionsData?.renewal_comp && (commissionsData?.renewal_comp_residual || commissionsData?.renewal_comp_upfront) ? 
                                        <StyledTableCell  
                                        colSpan={(commissionsData.renewal_comp && commissionsData.renewal_comp_upfront && commissionsData.renewal_comp_residual) ? 2 : 1}>Renewal Compensation</StyledTableCell>:''}
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell style={{background:"#FFF7F8",width:`calc(100% / ${dynamicWidth})`}} >
                                            Plan Term</StyledTableCell>
                                        <StyledTableCell style={{background:"#FFF7F8",width:`calc(100% / ${dynamicWidth})`}}>
                                            Plan Id</StyledTableCell>

                                        {(commissionsData?.acq_comp && commissionsData?.acq_comp_upfront) ? 
                                        <StyledTableCell style={{background:"#FFF7F8",width:`calc(100% / ${dynamicWidth})`}}>
                                            Upfront</StyledTableCell>:null}
                                        {(commissionsData?.acq_comp && commissionsData?.acq_comp_residual) ? 
                                        <StyledTableCell style={{background:"#FFF7F8",width:`calc(100% / ${dynamicWidth})`}}>
                                            Residual</StyledTableCell>:null}
                                        {(commissionsData?.renewal_comp && commissionsData?.renewal_comp_upfront) ? 
                                        <StyledTableCell style={{background:"#FFF7F8",width:`calc(100% / ${dynamicWidth})`}}>
                                            Upfront</StyledTableCell>:null}
                                        {(commissionsData?.renewal_comp && commissionsData?.renewal_comp_residual) ? 
                                        <StyledTableCell style={{background:"#FFF7F8",width:"20%"}}>
                                            Residual</StyledTableCell>:null}
                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.providerTableBody}>
                                    {allProductsData?.map((row, index) => {
                                        return(
                                        <StyledTableRow key={index}>
                                            {<StyledTableCell style={{textAlign:"center",width:"100%/5"}}><div>{row?.sg_product_name}</div></StyledTableCell>}
                                            {<StyledTableCell style={{textAlign:"center",width:"100%/5"}}><div>{row?.sg_product_id}</div></StyledTableCell>}
                                            {(commissionsData?.acq_comp && commissionsData?.acq_comp_upfront) ?<StyledTableCell style={{textAlign:"center"}}>
                                                <div>$&nbsp;{row.acq_comp_upfront}</div></StyledTableCell>:null}
                                            {(commissionsData?.acq_comp && commissionsData?.acq_comp_residual) ? <StyledTableCell style={{textAlign:"center"}}>
                                                <div>{row.acq_comp_residual}&nbsp;mils</div></StyledTableCell>:null}
                                            {(commissionsData?.renewal_comp && commissionsData?.renewal_comp_upfront) ?
                                             <StyledTableCell style={{textAlign:"center"}}><div>$&nbsp;{row.renewal_comp_upfront}</div></StyledTableCell>:null}
                                            {(commissionsData?.renewal_comp && commissionsData?.renewal_comp_residual) ?
                                             <StyledTableCell style={{textAlign:"center"}}><div>{row.renewal_comp_residual}&nbsp;mils</div></StyledTableCell>:null}
                                        </StyledTableRow>
                                    )})}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </>:
                <div style={{paddingTop:"20px"}}>
                    No Commission Data added.
                </div>
                }
            </Box>       
        </Box>
      
    </Box>
)
}

export default AccountSettings;